import type { GetServerSideProps } from 'next';
import { QueryClient, dehydrate } from 'react-query';

import sanitize from '@/utils/json/sanitize';
import { getIsAuthenticate } from '@/repository/is-authentication/server';

import type { NextPageWithLayout } from '@/model/types';
import { AUTH_REDIRECT } from '@/model/is-authenticate';

import DeviceLayout from '@/layouts/DeviceLayout';
import Module from '@/routes/Login';

/**
 * @function Page
 * @param props
 */
const Page: NextPageWithLayout = props => {
  return <Module {...props} />;
};

/**
 * @function getServerSideProps
 * @param context
 */
export const getServerSideProps: GetServerSideProps = async context => {
  const client = new QueryClient();

  /**
   * Server side redirection to make login inaccessible for authenticated users.
   */
  const { isAuth } = await getIsAuthenticate(client, context);
  if (isAuth) {
    return { redirect: AUTH_REDIRECT };
  }

  const dehydratedState = dehydrate(client);
  return {
    props: {
      dehydratedState: sanitize(dehydratedState),
    },
  };
};

Page.getLayout = page => {
  return (
    <DeviceLayout withDesktopTopNavigation withMobileTopNavigation>
      {page}
    </DeviceLayout>
  );
};

export default Page;
