import dynamic from 'next/dynamic';
import type { NextModules } from '@/model/types';

import { useAuth } from '@/context/authentication';
import { useMobile } from '@/context/responsive';

import { ConditionalBlock } from '@/components/ConditionalBlock';
import FullScreen from '@/components/Placeholder/FullScreen';

const Desktop = dynamic<unknown>(() => import('./layout/desktop'), {
  ssr: true,
  loading: () => <FullScreen />,
});

const Mobile = dynamic<unknown>(() => import('./layout/mobile'), {
  ssr: true,
  loading: () => <FullScreen />,
});

/**
 * @function Login
 */
const Login: NextModules = props => {
  const { loading } = useAuth();
  const isMobile = useMobile();

  return (
    <ConditionalBlock block={loading} placeholder={FullScreen}>
      {isMobile ? <Mobile {...props} /> : <Desktop {...props} />}
    </ConditionalBlock>
  );
};

export default Login;
