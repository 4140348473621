import type { VFC, ReactNode, ReactElement, FunctionComponent, Component, ComponentType } from 'react';
import { isValidElement, Fragment } from 'react';

interface PropsWithCalledComponent {
  children: ReactNode;
  block: boolean;
  placeholder?: ComponentType<any>;
}

interface PropsWithComponent {
  children: ReactNode;
  block: boolean;
  placeholder?: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
}

type Props = PropsWithCalledComponent | PropsWithComponent;

/**
 * @function ConditionalBlock
 * @params props
 */
const ConditionalBlock: VFC<Props> = props => {
  const { children, block, placeholder } = props;
  if (block) {
    if (isValidElement(placeholder)) {
      return placeholder;
    }
    if (typeof placeholder === 'function') {
      const Placeholder = placeholder;
      return <Placeholder />;
    }
    return null;
  }
  return <Fragment>{children}</Fragment>;
};

export default ConditionalBlock;
